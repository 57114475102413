<template>
    <div class="footercomponent">
        <div class="page-footer d-flex flex-column justify-content-between px-6 pt-4 pb-0" style="background-color: #00448b;height: auto !important;">
            <div class="row no-gutters align-items-center justify-content-center flex-nowrap">
                <div class="text-center">
                    <span class="h6 text-footer-down">© Copyright 2014 - {{ new Date().getFullYear() }}
                        <a class="footertext" href="https://www.digicollect.com/" target="_blank">DigiCollect</a>
                        - All Rights Reserved</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        methods:{
        },
        mounted() {
        }
    }
</script>
<style>
    .Line-7-Copy {
        width: 2px;
        height: 24px;
        border: solid 1px #e0e3e6;
    }

    .footer-help-icons {
        z-index: 1;
    }

    span.text-footer-down {
        font-size: 12px;
        text-align: center !important;
        color: #ffffff !important;
        font-weight: 600;
    }

    .footertext {
        color: #ffffff !important;
        text-decoration: none !important;
    }

    .page-footer {
        min-height: 58px;
        /* max-height: 58px; */

    }

    .no-gutters .textcenter-footer {
        text-align: center;
    }

    .bg-secondary-light {
        background-image: linear-gradient(to right,
                #0d84f2,
                #0d84f2 51%,
                #69b3fc,
                #9fcbff) !important;
    }

    .bottomfooter {
        background-color: #ffffff !important;
        height: 30px;
        bottom: 58px;
    }

    .helpdesk-footer {
        height: 38px;
        border-radius: 15px;
        background-color: #ffffff;
        border: solid 2px #0475f3;
        margin: -35px auto 0;
    }

    .text-footer {
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: #4e5764;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .help-desk-image {
        width: 17.7px;
        height: 17.7px;
        -o-object-fit: contain;
        object-fit: contain;
        margin-left: 4px;
        margin-right: 8px;
    }

    .col-20,
    .col-sm-20,
    .col-md-20,
    .col-lg-20,
    .col-xl-20 {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-20 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .copyright-footer-text-mob,
    .app-version-footer-text-mob {
        display: block;
        padding-bottom: 10px;
    }

    .copyright-footer-text-desktop,
    .app-version-footer-text-desktop {
        display: none;
    }

    .footer-content-justify {
        justify-content: space-between;
    }

    @media (min-width: 450px) {
        .app-version-footer-text-mob {
            display: none;
        }

        .app-version-footer-text-desktop {
            display: block;
        }
    }

    @media (max-width: 450px) {
        .footer-content-justify {
            justify-content: center;
        }
    }

    @media (min-width: 576px) {
        .col-sm-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (max-width: 930px) {
        .page-footer {
            min-height: 58px !important;
        }
    }

    @media (min-width: 768px) {
        .col-md-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
        .copyright-footer-text-mob {
            display: none;
        }

        .copyright-footer-text-desktop {
            display: block;
            padding-left: 20px;
        }
    }

    @media (min-width: 992px) {
        .col-lg-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (min-width: 1200px) {
        .col-xl-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
</style>